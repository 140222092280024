#form{
    width: 400px;
}



#regContainer{
  height: 100vh;
}

@media only screen and (max-width: 700px){
    #buttons{
        display: block;
      margin-top: 30px;
      margin-bottom: 20px;
      margin-left: 73px;
      align-items: center;
    }

    #form{
        display: block;
      margin-top: 25px;
      width: 58vw;
    }
    
}